import React, { useContext } from "react";
import { Field, FieldArray } from "redux-form";
import FormField from "forms/FormField";
import FormFieldArray from "forms/FormFieldArray";
import { formatPhone, phoneNormalize } from "utils/helpers";
import { email, required, requiredDropdown } from "utils/validators";
import { FormGroup, FormLabel, Badge } from "react-bootstrap";
import RenderIf from "hoc/render-if";

const LanguageSelector = ({ languages }) => {
  return (
    <FormGroup>
      <Field
        label="Language"
        name="languageId"
        component={FormField}
        type="select"
        options={languages}
        validate={requiredDropdown}
        optionField="name"
      />
    </FormGroup>
  );
};

const BadgeComponent = ({ label, idx }) => {
  return <Badge>{label}</Badge>;
};

const typePhoneConditioin = (initialValues, idx) => {
  const type = initialValues?.phoneNumbers[idx]?.info?.carrier?.type;
  if (type) {
    return type !== "mobile";
  }
  return false;
};

const CreateUpdateDonor = (props) => {
  const { leadSources, editing, languages, initialValues } = props;
  return (
    <React.Fragment>
      <FormGroup>
        <Field
          name="fname"
          type="text"
          component={FormField}
          label="First Name"
          placeholder=""
          required={true}
          validate={required}
        />
      </FormGroup>
      <FormGroup>
        <Field
          name="lname"
          type="text"
          component={FormField}
          label="Last Name"
          placeholder=""
          required={true}
          validate={required}
        />
      </FormGroup>
      <FormGroup>
        <Field
          name="email"
          type="text"
          component={FormField}
          label="Email"
          placeholder=""
          validate={[email]}
        />
      </FormGroup>
      <FormGroup>
        <FieldArray
          name="phoneNumbers"
          component={FormFieldArray}
          label="Phone Number"
          placeholder=""
          addButtonLabel="+ Add Another"
          multiFieldFormat={[formatPhone]}
          multiFieldNormalizing={[phoneNormalize]}
          multiFieldDisabled={[false, false, true, true, true]}
          multipleFields={true}
          multiFieldHide={[
            false,
            (idx) => typePhoneConditioin(initialValues, idx),
            (idx) =>
              !initialValues?.phoneNumbers[idx]?.verificationInProgress ||
              initialValues?.phoneNumbers[idx]?.verified,
            (idx) => !initialValues?.phoneNumbers[idx]?.verified,
            (idx) => !typePhoneConditioin(initialValues, idx),
          ]}
          fieldNames={[
            "number",
            "receiveNotifications",
            "verificationInProgress",
            "verified",
            "landlines",
          ]}
          fieldLabels={[
            "Phone",
            "Send Notifications",
            "Verification in Progress",
            "Verified",
            "Landline",
          ]}
          multiFieldCustomeComponent={[
            null,
            null,
            (idx, label) => <BadgeComponent label={label} />,
            (idx, label) => <BadgeComponent label={label} />,
            (idx, label) => <BadgeComponent label={label} />,
          ]}
          multiFieldTypes={[
            "text",
            "checkbox",
            "customeComponent",
            "customeComponent",
            "customeComponent",
          ]}
          multiFieldRequired={[true, false, false, false, false]}
          multiFieldValidation={[required, null, null, null, null]}
          rows={true}
        />
      </FormGroup>
      <FormGroup controlId="formControlsSelect">
        <Field
          label={"Lead Source"}
          name="leadSourceId"
          component={FormField}
          type={"select"}
          placeholder=""
          options={leadSources}
        />
      </FormGroup>
      <LanguageSelector languages={languages} />
      <RenderIf condition={!editing}>
        <FormGroup>
          <FormLabel>Pickup Notifications</FormLabel>

          <FormGroup>
            <Field
              name="pickupNotifications.email"
              label={"Email"}
              component={FormField}
              type="checkbox"
            />

            <Field
              label={"SMS"}
              name="pickupNotifications.text"
              component={FormField}
              type="checkbox"
            />
          </FormGroup>
        </FormGroup>

        <FormGroup>
          <label htmlFor="allowMarketingCalls">Marketing Calls Allowed</label>

          <Field
            label={"Yes"}
            name="allowMarketingCalls"
            component={FormField}
            type="checkbox"

            //   component={"input"}
          />
        </FormGroup>
      </RenderIf>
    </React.Fragment>
  );
};

export default CreateUpdateDonor;
