import React, { Component } from "react";
import Header from "./header";
// import Footer from "./footer";

const DriverComponent = ({ children }) => {
  return (
    <div id={"driver-main-container"}>
      <div id={"driver-row-container"}>
        <div id={"driver-page-container"}>
          <Header driverMode />
          <div id={"driver-page-body"}>{children}</div>
        </div>
      </div>
    </div>
  );
};

export default DriverComponent;
